import api from './api';
// 获取校园商圈商户列表
export const $sg_getSchoolGroupShopsList = (params) => api.get('/sssk/api/agent/biz/tradingAreaShop/page', params);
// 获取商圈
export const $sg_getAllGroupShops = (params) => api.get('/sssk/api/agent/biz/tradingAreaShop/getTradingArea', params);
// 移除商户
export const $sg_removeSchoolShop = (params) => api.delete('/sssk/api/agent/biz/tradingAreaShop/delete', params);
// 批量修改分类
export const $sp_changeShopsCategory = (params) => api.post('/sssk/api/agent/biz/tradingAreaShop/updateCategoryBatch', params);
// 新增商户列表
export const $sg_addShopsList = (params) => api.get('/sssk/api/agent/biz/tradingAreaShop/all', params);
// 新增商户
export const $sp_addShops = (params) => api.post('/sssk/api/agent/biz/tradingAreaShop/addTradingAreaShop', params);
// 获取店铺所有商品
export const $sg_getAllShopGoods = (params) => api.get(`/sssk/api/agent/biz/TradingAreaLayoutMemberProduct/queryGoodsByShopId/?shopId=${params}`);
// 获取店铺已设置商品广告
export const $sg_getShopSettingBannerList = (params) => api.get(
  `/sssk/api/agent/biz/TradingAreaLayoutMemberProduct/queryTradingAreaLayoutMemberProductList/${params}`,
);
// 保存商品推荐设置
export const $sp_createShopSetting = (params) => api.post('/sssk/api/agent/biz/TradingAreaLayoutMemberProduct/saveCommodityRecommendConfig',
  params);
// 修改商品推荐设置
export const $sp_upDateShopSetting = (params) => api.post(
  '/sssk/api/agent/biz/TradingAreaLayoutMemberProduct/updateCommodityRecommendConfig',
  params,
);


// 优惠券列表
export const $sg_platCouponList = (params) => api.get('/w/platCoupon/list', params);

// 优惠券详情
export const $sg_platCouponInfo = (params) => api.get('/w/platCoupon/info', params);

// 优惠券创建
export const $sp_platCouponSave = (params) => api.post('/w/platCoupon/save', params);

// 改变优惠券状态
export const $sp_saveStatus = (params) => api.post('/w/platCoupon/saveStatus', params);

// 商圈列表
export const $sg_areas = (params) => api.get('/w/platCoupon/areas', params);

// 选择商户列表
export const $sg_platCouponChoseList = (params) => api.get('/w/platCoupon/choseList', params);

// 创建了优惠券的商户列表
export const $sg_activityShopList = (params) => api.get('/w/platCoupon/shopList', params);

// 优惠券订单列表
export const $sg_platCouponOrders = (params) => api.get('/w/platCoupon/orders', params);

// 优惠券优惠金额统计
export const $sg_platCouponTotalDiscount = (params) => api.get('/w/platCoupon/totalDiscount', params);
