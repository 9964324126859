<template>
  <el-dialog width="1200px" :visible='showAddShopsDialog' center @close="closeAddGroupShops">
    <span slot='title' style="font-size:18px;font-weight:bold;">{{tradingAreaName}}</span>
    <div class="groupBuyingList">
      <div class="tableBox">
        <el-table ref="multipleTable" :row-key="getRowKey" :data="groupShopTableData" style="width: 100%" border
          @selection-change="selectRow">
          <el-table-column prop="" label="选择" reserve-selection :selectable="selectAble" align="center" type="selection">
          </el-table-column>
          <el-table-column prop="shopCode" label="商户号" align="center"></el-table-column>
          <el-table-column prop="shopName" label="商户名" align="center"></el-table-column>
          <el-table-column prop="contactTel" label="商户联系方式" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.contactTel? scope.row.contactTel:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="商户添加时间" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.createTime? scope.row.createTime:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="抽佣比例" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.amount ? scope.row.amount+'%':'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status===1">营业</span>
              <span v-if="scope.row.status===2">预定</span>
              <span v-if="scope.row.status===3">打烊</span>
              <span v-if="scope.row.status===4">停业</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
      <div slot='footer'>
        <div class="flexbox flex-lr flex-align-center mt20">
          <el-input v-model="form.shopName" placeholder="商户名称" style="width:200px;margin-left:20px;" clearable></el-input>
          <el-input v-model="form.contactTel" type="number" placeholder="商户账号" style="width:200px;margin-left:20px;" class="mr20" clearable>
          </el-input>
          <el-button type="primary" @click="searchAddShop">筛选</el-button>
          <el-button type="info" @click="clear">清空</el-button>
          <el-button type="primary" @click="confirmAddButton">确认添加</el-button>
          <el-button type="info" @click="closeAddGroupShops">关闭</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible="confirmAddShopsDialog" center @close="confirmAddShopsDialog=false" append-to-body width="500px"
      class="confirmDialog">
      <img src="/img/dialog/exploreIcon.png" alt="">
      <p style="font-size:16px;" class="mt10">确认新增选择商户</p>
      <p class="mt10">新增后商户将根据用户距离进行排序展示</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmAddShopsDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAddShop">确定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { $sg_addShopsList, $sp_addShops } from '@/api/schoolGroupShops';


export default {
  props: {
    tradingAreaId: {
    },
    tradingAreaName: {
    },
  },
  data() {
    return {
      showAddShopsDialog: false,
      confirmAddShopsDialog: false, // 确认添加弹窗
      form: {
        contactTel: '',
        shopName: '',
      },
      totalPage: 1,
      currentPage: 1,
      groupShopTableData: [
      ],
      selectedArrary: [], // 选中的行数组
    };
  },
  components: {
  },
  created() {
  },
  methods: {
    // 保留当前分页选中
    getRowKey(row) {
      return row.shopId;
    },
    selectAble(row) {
      if (row.isAdd === 1) {
        return false;
      } else {
        return true;
      }
    },
    //  选中商店时
    selectRow(selectedArr) {
      console.log(selectedArr.isAdd);
      this.selectedArrary = selectedArr.map((v) => v.shopId);
      console.log(this.selectedArrary);
    },
    //   打开弹窗
    openAddGroupShops() {
      this.getAddShopsList();
      this.showAddShopsDialog = true;
    },
    // 关闭弹窗
    closeAddGroupShops() {
      this.form = {
        contactTel: '',
        shopName: '',
      };
      this.showAddShopsDialog = false;
    },
    // 确认添加弹窗
    confirmAddButton() {
      if (this.selectedArrary.length === 0) {
        this.$message.error('您未选中任何商户');
        return;
      }
      this.confirmAddShopsDialog = true;
    },
    // 确认添加商户
    confirmAddShop() {
      $sp_addShops({
        shopIds: this.selectedArrary,
        tradingAreaId: this.tradingAreaId,
      }).then(() => {
        this.confirmAddShopsDialog = false;
        this.$parent.getGroupShopsTableData();
        this.getAddShopsList(this.form);
        this.$refs.multipleTable.clearSelection();
      });
    },
    // 筛选
    searchAddShop() {
      this.getAddShopsList(this.form);
      this.currentPage = 1;
    },
    // 清空
    clear() {
      this.form = {
        contactTel: '',
        shopName: '',
      };
      this.currentPage = 1;
      this.getAddShopsList();
    },
    // 请求列表数据
    getAddShopsList(params) {
      $sg_addShopsList({
        pageCurrent: this.currentPage,
        pageSize: 15,
        tradingAreaId: this.tradingAreaId,
        ...params,
      }).then((res) => {
        console.log(res);
        this.groupShopTableData = res.records;
        this.totalPage = res.pages;
      }).catch(() => {

      });
    },
    // 翻页
    pageChange() {
      this.getAddShopsList(this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmDialog {
  /deep/.el-dialog--center {
    .el-dialog__body {
      text-align: center;
    }
  }
}
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
}
</style>

