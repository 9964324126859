<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
      <el-select v-model="form.tradingAreaId" class="ml20 mr20" placeholder="归属商圈" style="width:150px;">
        <el-option v-for="item in tradingAreaOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input v-model="form.shopName" placeholder="商户名称" style="width:200px;" clearable></el-input>
      <el-input v-model="form.contactTel" type="number" placeholder="商店电话" class="ml20 mr20" style="width:200px;" clearable></el-input>
      <el-button type="info" @click="clear">清空</el-button>
      <el-button type="primary" @click="schoolGroupSearch">搜索</el-button>
      <el-button type="primary" @click="addShops">新增商户<i class="el-icon-circle-plus-outline"></i></el-button>
      <el-button type="primary" @click="openChangeShopClassification">批量修改分类</el-button>
      <!-- <el-checkbox v-model="a">备选项{{scope}}</el-checkbox> -->
    </div>
    <div class="tips shops-tips "> <span>商户数：{{total}}</span> <span>注：代理商需先配置商圈“首页布局”后，商户才能分类</span></div>
    <div class="tableBox">
      <el-table ref="schoolGroupList" v-loading="loading" :row-key="getRowKey" :data="shopGroupTable" style="width: 100%;" border
        @selection-change="selectShops">
        <el-table-column prop="" label="选择" align="center" reserve-selection type="selection">
        </el-table-column>
        <el-table-column prop="tradingAreaName" label="归属商圈" align="center"></el-table-column>
        <el-table-column prop="shopCode" label="商户号" align="center"></el-table-column>
        <el-table-column prop="shopName" label="商户名" align="center"></el-table-column>
        <el-table-column prop="contactTel" label="商户联系方式" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.contactTel? scope.row.contactTel:'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="添加商户时间" align="center">
          <template slot-scope="scope">{{scope.row.createTime || '-'}}</template>
        </el-table-column>
        <el-table-column prop="amount" label="抽佣比例" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.amount ? scope.row.amount+'%':'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="categoryName" label="商户类型" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.categoryName? scope.row.categoryName:'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="店铺列表商品广告位" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="settingBunner(scope.row)">设置</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status===1">营业</span>
            <span v-if="scope.row.status===2">预定</span>
            <span v-if="scope.row.status===3">打烊</span>
            <span v-if="scope.row.status===4">停业</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="removeGroup(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange"
      :page-size="15">
    </el-pagination>
    <!-- 设置广告弹窗 -->
    <el-dialog :visible='showSettingGroupBanner' width="800px" center @close="showSettingGroupBanner=false">
      <span slot="title" style="font-size:18px;font-weight:bold;">店铺列表商品广告位</span>
      <div class="settingGroupBannerContent">
        <p class="shopName">商户名：{{shopName}}</p>
        <el-table :data="shopBannerTabbleData" style="width: 100%;heigh:100%;" border>
          <el-table-column prop="" label="排序" width="50px" align="center" type="index" :index="indexMethod"></el-table-column>
          <el-table-column prop="goodsId" label="商品id" align="center" width="150px">
            <template slot-scope="scope">
              <!-- <span @click="openInput">{{scope.row.goodsId}}</span> -->
              <!-- eslint-disable-next-line vue/valid-v-model -->
              <el-select v-model="scope.row.checkId" filterable remote placeholder="输入商品Id"
                :remote-method="(query)=>getGroupShopGoods(query,scope.$index,1)" @change="(val)=>selectedOption(val,1,scope.$index)">
                <el-option v-for="item in goodsIdOptios" :key="item.value" :label="item.value" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称" align="center" width="150px">
            <template slot-scope="scope">
              <el-select v-model="scope.row.goodsName" filterable remote placeholder="输入商品名称"
                :remote-method="(query)=>getGroupShopGoods(query,scope.$index,2)" @change="(val)=>selectedOption(val,2,scope.$index)">
                <el-option v-for="item in goodsIdOptios" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goodsPath" label="商品图片" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.goodsPath" alt="" style="width:100px;heigh:50px">
            </template>
          </el-table-column>
          <el-table-column prop="price" label="商品售价" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.rebatePrice||scope.row.price}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-popconfirm @confirm='removeGroupBanner(scope.row,scope.$index)' confirm-button-text='是的' cancel-button-text='不了'
                icon="el-icon-warning-outline" icon-color="red" title="您确定删除吗？">
                <el-button v-show="scope.row.goodsId" slot="reference">移除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <p class="tips" style="margin-top:5px;">注：至少设置4个广告位，少于4个用户端不显示</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSettingGroupBanner = false">取 消</el-button>
        <el-button type="primary" @click="bannerSettingSubmit">保存并发布</el-button>
      </span>
    </el-dialog>
        <Dialog ref="delectDialog" dialogWidth="500px">
            <div class="dialogBtn flexbox flex-align-center flex-justify-center">
                <el-button class="btn" type="primary" @click="$refs.delectDialog.showDialog = false">返回</el-button>
                <el-button class="btn" type="info" @click="confirmDelect">确认移除</el-button>
              </div>
          </Dialog>
    <AddShops ref="addShopDialog" :tradingAreaName="tradingAreaName" :tradingAreaId="form.tradingAreaId"></AddShops>
    <el-dialog :visible='showChangeClassification' center @close="showChangeClassification =false" width="500px">
      <span slot="title" style="font-size:18px;font-weight:bold;">修改商户分类</span>
      <div>
        <span>请选择商户分类</span>
        <el-select class="ml20" v-model="shopsClassificationId" placeholder="请选择分类">
          <el-option v-for="item in shopClassificationOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showChangeClassification = false">取 消</el-button>
        <el-button type="primary" @click="shopClassificationSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Dialog from '@/components/common/dialog.vue';
import {
  $sg_getSchoolGroupShopsList, $sg_getAllGroupShops, $sg_removeSchoolShop, $sp_changeShopsCategory,
  $sg_getAllShopGoods, $sg_getShopSettingBannerList,
  $sp_createShopSetting, $sp_upDateShopSetting,
} from '@/api/schoolGroupShops';
import { $sg_getSelectedTradingAreaCate } from '@/api/tradingAreaLayout';
import AddShops from './components/AddShops.vue';

export default {
  data() {
    return {
      isCreate: '', // 是否设置过商铺广告
      loading: false,
      shopsClassificationId: [], // 批量修改商户选择的商店分类Id
      showChangeClassification: false, // 批量修改商户状态弹窗
      id: '', // 要删除的商户主键id
      searchResultOptions: [], // 查询的商品数据数组
      showSettingGroupBanner: false, // 设置店铺列表商品广告位弹窗
      shopName: '', // 选中的商户名称 
      shopId: '', // 选中的商户id
      tradingAreaName: '', // 选中的商圈名字
      form: {
        tradingAreaId: '',
        contactTel: '',
        shopName: '',
      },
      time: '',
      totalPage: 1,
      total: 0,
      currentPage: 1,
      shopGroupTable: [ // 商圈列表
      ],
      shopBannerTabbleData: [
        {},
        {},
        {},
        {},
        {},
        {},
      ], // 用户已有店铺广告列表
      selectedShopsArr: [], // 选中的商户数组
      groupShopBannerTable: [// 商圈店铺广告表格
      ],
      goodsNameOptions: [], // 商圈店铺广告的商品名数组
      goodsIdOptios: [], // 商圈店铺广告的id数组
      isOpenInput: false, // 是否打开店铺商品搜搜索框
      tradingAreaOptions: [ // 商圈数组
      ],
      shopClassificationOptions: [], // 商户分类的选项
    };
  },
  components: {
    Dialog,
    AddShops,
  },
  async created() {
    await this.getGroupShops();
    await this.getGroupShopsTableData();
  },
  methods: {
    // 保留当前分页选中
    getRowKey(row) {
      return row.id;
    },
    // 获取商圈
    getGroupShops() {
      return new Promise((resolve) => {
        $sg_getAllGroupShops().then((res) => {
          console.log(res);
          this.tradingAreaOptions = res.map((v) => ({
            label: v.tradingAreaName,
            value: v.tradingAreaId,
          }));
          this.form.tradingAreaId = this.tradingAreaOptions[0].value;
          const index = this.tradingAreaOptions.findIndex((v) => v.value === this.form.tradingAreaId);
          this.tradingAreaName = this.tradingAreaOptions[index].label;
          resolve();
        });
      });
    },
    // 打开批量修改商户分类弹窗
    openChangeShopClassification() {
      if (this.selectedShopsArr.length === 0) {
        this.$message.error('您未选中任何一个商户');
        return;
      }
      this.showChangeClassification = true;
      $sg_getSelectedTradingAreaCate({ tradingAreaId: this.form.tradingAreaId }).then((res) => {
        if (res.length === 0) {
          this.$message.error('请先设置商圈分类');
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.shopClassificationOptions = res.map((v) => ({
            value: v.categoryId,
            label: v.categoryName,
          }));
          console.log(this.shopClassificationOptions);
        }
      }).catch(() => {
      });
    },
    // 选中的商店
    selectShops(selectedArr) {
      this.selectedShopsArr = selectedArr.map((v) => v.id);
      console.log(this.selectedShopsArr);
    },
    // 确认批量修改商户分类
    shopClassificationSubmit() {
      if (!this.shopsClassificationId) {
        this.$message.error('您未选择任何分类');
      }
      const index = this.shopClassificationOptions.findIndex((v) => v.value === this.shopsClassificationId);
      $sp_changeShopsCategory({
        categoryName: this.shopClassificationOptions[index].label,
        tradingAreaId: this.form.tradingAreaId,
        categoryId: this.shopsClassificationId,
        ids: this.selectedShopsArr,
      }).then((res) => {
        console.log(res);
        this.getGroupShopsTableData(this.form);
        this.$refs.schoolGroupList.clearSelection();
      }).catch(() => {

      });
      this.showChangeClassification = false;
    },
    // 搜索
    schoolGroupSearch() {
      const params = this.form;
      this.currentPage = 1;
      this.getGroupShopsTableData(params);
    },
    // 清空
    clear() {
      this.form = {
        tradingAreaId: '',
        contactTel: '',
        shopName: '',
      };
      this.form.tradingAreaId = this.tradingAreaOptions[0].value;
      this.currentPage = 1;
      this.getGroupShopsTableData(this.form);
    },
    // 请求列表数据
    getGroupShopsTableData(params) {
      return new Promise((resolve) => {
        this.loading = true;
        console.log(this.tradingAreaOptions, '123131213');
        const tradingAreaId = params ? params.tradingAreaId : this.form.tradingAreaId;
        $sg_getSchoolGroupShopsList({
          pageCurrent: this.currentPage,
          pageSize: 15,
          tradingAreaId,
          ...params,
        }).then((res) => {
          this.totalPage = res.pages;
          this.total = res.total;
          const { records } = res;
          this.shopGroupTable = records;
          this.loading = false;
          resolve();
        }).catch(() => {
          console.log('请求失败');
          this.loading = false;
        });
      });
    },
    // 新增商店
    addShops() {
      this.$refs.addShopDialog.openAddGroupShops();
    },
    // 设置店铺广告
    async settingBunner(row) {
      this.shopName = row.shopName;
      this.shopId = row.shopId;
      // 获取用户已设置的商品广告列表（商品id为producId）
      await $sg_getShopSettingBannerList(row.shopId).then((res) => {
        console.log(res, '&&&&&&&&&');
        if (!res || res.length === 0) {
          this.isCreate = true;
          this.shopBannerTabbleData = [{}, {}, {}, {}, {}, {}];
        } else {
          this.shopBannerTabbleData = res;
          if (this.shopBannerTabbleData.length <= 5) {
            for (let i = this.shopBannerTabbleData.length; i < 6; i += 1) {
              this.shopBannerTabbleData.push({});
            }
          }
          // checkId用于model绑定 goodsId用于判断
          this.shopBannerTabbleData.map((v) => {
            // eslint-disable-next-line no-param-reassign
            v.goodsId = v.productId;
            // eslint-disable-next-line no-param-reassign
            v.checkId = v.goodsId;
            return v;
          });
          this.isCreate = false;
        }
      }).catch(() => {
        this.shopBannerTabbleData = [{}, {}, {}, {}, {}, {}];
      });

      // 获取商户下所有商品列表（商品id 为goodsId）
      await $sg_getAllShopGoods(row.shopId).then((res) => {
        this.changeShopGoodsList = JSON.parse(JSON.stringify(res));
        this.goodsIdOptios = this.changeShopGoodsList.map((v) => ({ value: v.goodsId, label: v.goodsName }));
      }).catch(() => {
      });
      this.showSettingGroupBanner = true;
    },
    // 选中要要添加的店铺商品
    selectedOption(val, type, index) {
      console.log(val, '123123123131312');
      const arr = this.chooseShopItem(val, index) || {};
      if (arr.goodsId) {
        const form = { ...arr };
        form.sort = index;
        form.flag = 1;
        form.checkId = form.goodsId;
        this.shopBannerTabbleData.splice(index, 1, form);
      }
      console.log(this.shopBannerTabbleData);
      this.goodsIdOptios = this.changeShopGoodsList.map((v) => ({ value: v.goodsId, label: v.goodsName }));
    },
    // 选择商户
    chooseShopItem(val, index = null, type, isTable = true) {
      if (isTable && index != null) {
        const hadIndex = this.shopBannerTabbleData.findIndex((item) => item.goodsId === val);
        console.log(hadIndex);
        if (hadIndex > -1) {
          this.$message.error('该商品已选择');
          this.$set(this.shopBannerTabbleData, index, {});
          return false;
        }
      }
      return this.changeShopGoodsList.find((item) => item.goodsId === val);
    },
    // 打开移除商户弹窗
    removeGroup(row) {
      console.log(row);
      this.id = row.id;
      this.$refs.delectDialog.showDialog = true;
      this.$refs.delectDialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '商圈将移除此商户',
        content: '移除商户后，此商户将不在此商圈进行展示，如移除后，需要继续展示请在商圈添加此商户',
      };
    },
    // 确认移除商户
    confirmDelect() {
      $sg_removeSchoolShop({ id: this.id }).then(() => {
        this.$message.success('移除成功');
        this.getGroupShopsTableData();
      });
      this.$refs.delectDialog.showDialog = false;
    },
    // 移出店铺列表广告
    removeGroupBanner(row, index) {
      this.shopBannerTabbleData.splice(index, 1, {});
    },
    // 保存并发布店铺广告
    bannerSettingSubmit() {
      const arr = JSON.parse(JSON.stringify(this.shopBannerTabbleData));
      let sendArr = arr.filter((item) =>
        // eslint-disable-next-line eqeqeq
        // eslint-disable-next-line implicit-arrow-linebreak
        Object.keys(item).length !== 0);
      sendArr = sendArr.map((v) => ({
        productId: v.goodsId || v.productId,
        shopId: this.shopId,
        sort: v.sort,
        state: 1,
        tradingAreaId: this.form.tradingAreaId,
      }));
      console.log(sendArr, '这是发送的数据222');
      if (this.isCreate) {
        $sp_createShopSetting(
          sendArr,
        ).then(() => {

        }).catch(() => {

        });
      } else {
        $sp_upDateShopSetting(
          sendArr,
        ).then(() => {

        }).catch(() => {

        });
      }
      this.showSettingGroupBanner = false;
      this.getGroupShopsTableData();
    },
    // 搜索店铺列表商品
    getGroupShopGoods(query, index, type) {
      // eslint-disable-next-line no-useless-return
      if (!query) return;
      let goodsId = '';
      let goodsName = '';
      if (type === 1) {
        goodsId = query;
      } else {
        goodsName = query;
      }
      console.log(query, index);
      // 获取商户下所有商品列表
      $sg_getAllShopGoods(`${this.shopId}&goodsId=${goodsId}&goodsName=${goodsName}`).then((res) => {
        const searchList = JSON.parse(JSON.stringify(res));
        this.goodsIdOptios = searchList.map((v) => ({ value: v.goodsId, label: v.goodsName }));
      }).catch(() => {
      });
      // const endList = this.shopGoodsList.filter((goods) => {
      //   if (query === '') return true;
      //   return goods.goodsName.indexOf(query) > -1;
      // });
      // this.goodsNameOptions = endList;
    },
    // 翻页
    pageChange() {
      this.getGroupShopsTableData(this.form);
    },
    // 序号
    indexMethod(index) {
      // eslint-disable-next-line no-return-assign
      return index + 1;
    },

  },
};
</script>
<style lang="scss" scoped>
.shops-tips {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #ef3f46;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  > .el-table {
    min-height: 580px;

    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
  /deep/.el-checkbox__inner {
    border-radius: 50%;
  }
}
</style>

